import dynamic from 'next/dynamic';
import { renderLineBreaks } from '../../../utils';
import EditorJSContent from '../../editor-js-content';
import { NextImage } from '../index';
import {
  SlideContentWrapper,
  TitleWrapper,
  SlideTitleWrapper,
  DescriptionWrapper,
  BasicSlideWrapper,
  ButtonMobileWrapper,
  NewTitleWrapper,
  NewSlideContentWrapper,
  NewDescriptionWrapper,
  NewTextWrapper
} from './styles';

const Link = dynamic(() => import('../link/link'));

const Title = ({ title, children }) => {
  return (
    <TitleWrapper>
      <h1>{renderLineBreaks(title)}</h1>
      {children}
    </TitleWrapper>
  );
};

const Description = ({ children }) => {
  return <DescriptionWrapper>{renderLineBreaks(children)}</DescriptionWrapper>;
};
const DescriptionRichText = ({ descriptionRichText }) => {
  return (
    <DescriptionWrapper>
      <EditorJSContent content={descriptionRichText} />
    </DescriptionWrapper>
  );
};

export const BasicSlide = ({
  title,
  description,
  descriptionRichText,
  media,
  link,
  reversed,
  newVersion
}) => {
  return (
    newVersion ? (
      <NewSlideContentWrapper>
        <NewTextWrapper>
          <NewTitleWrapper>
            <h1>{renderLineBreaks(title)}</h1>
          </NewTitleWrapper>
          {descriptionRichText ? (
            <NewDescriptionWrapper descriptionRichText={descriptionRichText} />
          ) : (
            description && <NewDescriptionWrapper>{renderLineBreaks(description)}</NewDescriptionWrapper>
          )}
          {link && (
            <Link type="buttonPrimary" {...link}>
              {link.text}
            </Link>
          )}
        </NewTextWrapper>

        {media && <NextImage media={media} />}
      </NewSlideContentWrapper>
    )
      :
      <BasicSlideWrapper reversed={reversed}>
        <SlideContentWrapper>
          <SlideTitleWrapper>
            <Title title={title}>
              {link && (
                <Link type="buttonPrimary" {...link}>
                  {link.text}
                </Link>
              )}
            </Title>
          </SlideTitleWrapper>
          {descriptionRichText ? (
            <DescriptionRichText descriptionRichText={descriptionRichText} />
          ) : (
            description && <Description>{description}</Description>
          )}
        </SlideContentWrapper>
        {link && (
          <ButtonMobileWrapper>
            <Link type="buttonPrimary" {...link}>
              {link.text}
            </Link>
          </ButtonMobileWrapper>
        )}
        {media && <NextImage media={media} />}
      </BasicSlideWrapper>
  );
};

export default BasicSlide;
